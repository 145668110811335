<template>
  <li
    v-if="id"
    :class="[{mother: label==='母'},{father: label==='父'}]"
  >
    <router-link :to="{path: '/kennel/' + animalId}">
      <label>{{label}}</label>
      <picture>
        <img :src="thumbUrl">
      </picture>
      <span>{{name}}</span>
    </router-link>
  </li>
</template>

<script>
import axios from "axios"
export default {
  props: ["label","id"],
  data() {
    return {
      thumbUrl: "",
      name: "",
    }
  },
  computed: {
    className() {
      return this.$props.label === "母" ? "mother" : "father" 
    }
  },
  watch: {
    id(newVal) {
      if(!newVal)
        return

      this.animalId = parseInt(newVal)
      axios
        .get("/wp-json/pasti/v1/animal/abstract/" + newVal )
        .then((response) => {
          this.name = response.data.name
          this.thumbUrl = response.data.thumbUrl
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
</script>

<style scoped>
li {
  position: relative;
}
li label {
  position: absolute;
  top: -10px;
  left: -10px;
  line-height: 1.0;
  font-weight: bold;
  background-color: #000;
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
li.mother label { background-color: var(--bs-pink); }
li.father label { background-color: var(--bs-indigo); }
li a {
  display: flex;
  align-items: center;
  background-color: rgba(255,255,255,0.5);
  padding: 10px;
  border-radius: 15px;
}
li picture {
  width: 50px;
  flex-basis: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  border: 2px solid #fff;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.4);
}
li picture img {
  width: 100%;
  height: auto;
  display: block;
}
li span {
  font-size: 0.9rem;
  font-weight: bold;
}
</style>