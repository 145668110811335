<template>
  <b-modal
    title="削除理由を選択してください"
    v-model="active"
    :no-close-on-backdrop=true
    ok-title="次へ"
    cancel-title="キャンセル"
    @hidden="$emit('reset')"
    @ok="submit"
  >

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 154px;">削除理由</span>
        <select
          class="form-select"
          v-model="reason"
        >
          <option value="sold">販売・譲渡</option>
          <option value="dead">死亡</option>
        </select>
        <div class="invalid-feedback">削除理由を選択してください</div>
      </div>
    </fieldset>

  </b-modal>
</template>

<script>
export default {
  props: ["mode"],
  emits: ["reason", "reset"],
  data() {
    return {
      active: false,
      reason: ""
    }
  },
  mounted() {
    this.active = this.$props.mode === "deletereason" ? true : false
  },
  methods: {
    submit() {
      this.$emit('reason', this.reason)
    }
  }
}
</script>