<template>
  <b-modal
    title="死亡記録"
    v-model="active"
    :no-close-on-backdrop=true
    hide-footer
    ok-title="保存"
    cancel-title="キャンセル"
    @hidden="$emit('reset')"
  >

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 154px;">死亡日時</span>
        <input
          type="datetime-local"
          class="form-control"
          :class="{'is-invalid': !states.datetime}"
          v-model="container.datetime"
        >
        <div class="invalid-feedback">死亡日時を入力してください</div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 154px;">死亡理由</span>
        <input
          type="text"
          class="form-control"
          :class="{'is-invalid': !states.cause}"
          v-model="container.cause"
        >
        <div class="invalid-feedback">死亡日時を入力してください</div>
      </div>
    </fieldset>

    <fieldset>
      <div>
        <label>備考</label>
        <textarea
          class="form-control"
          v-model="container.memo"
        ></textarea>
      </div>
    </fieldset>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
      >キャンセル</button>
      <button
        type="button"
        class="btn btn-primary"
        @click="handleOk"
      >記録する</button>
    </div>

  </b-modal>
</template>

<script>
export default {
  props: ["mode","animalData"],
  emits: ["reset"],
  data() {
    return {
      active: false,
      container: {
        datetime: "",
        cause: "",
        memo: ""
      },
      states: {
        datetime: true,
        cause: true,
      }
    }
  },
  mounted() {
    this.active = this.$props.mode === "dead" ? true : false

    this.$store.watch(
      (state, getters) => getters['Animal/processing'],
      (newValue,oldValue) => {
        if(newValue === false && oldValue === true){
          this.$emit('reset')
          this.active = false
          this.$router.push('/kennel')
        }
      }
    )
  },
  methods: {
    validate() {
      this.states.datetime = this.container.datetime ? true : false
      this.states.cause = this.container.cause ? true : false

      let res = true
      let states = {...this.states}
      Object.keys(states).forEach(function(key) {
        res *= states[key]
      })
      return res
    },
    handleOk() {
      if(!this.validate())
        return
      this.$store.dispatch("Animal/dead", this.container)
    }
  }
}
</script>