<template>
  <div id="summary">

    <div class="primary">
      <div class="pic" v-if="animalData.thumbUrl">
        <img :src="animalData.thumbUrl">
      </div>
      <div class="desc">
        <h3>{{animalData.control_number}} <i>{{sex}}{{animalData.age}}才</i></h3>
        <div class="my-2">
          <div class="breed">{{breed}}</div>
          <div class="id">No.{{animalData.microchip_no}}</div>
          <div
            v-if="animalData.sex === 'female'"
            class="num-birth"
          >出産回数：{{animalData.numBirth > 0 ? animalData.numBirth : 0}}回</div>
        </div>

        <div class="controls">
          <div v-if="animalData.IsSold === '1'">
            <button class="btn btn-secondary">販売・譲渡済</button>
          </div>
          <div v-else-if="animalData.IsDead === '1'">
            <button class="btn btn-secondary">死亡</button>
          </div>
          <div v-else>
            <button
              class="btn me-2"
              :class="[isOnSale === '1' ? availableClass : unavailableClass]"
              @click="toggleIsOnSale"
            >販売譲渡<span v-html="onSaleLabel"></span></button>
            <button
              class="btn btn-primary"
              :class="[isBreedable === '1' ? availableClass : unavailableClass]"
              @click="toggleIsBreedable"
            >繁殖<span v-html="breedableLabel"></span></button>
          </div>
        </div>

      </div>     
    </div>

    <div class="secondary">
      <ul class="parents">
        <AnimalCard
          :label="'母'"
          :id="animalData.mother"
        />
        <AnimalCard
          :label="'父'"
          :id="animalData.father"
        />
      </ul>
    </div>

  </div>
</template>

<script>
import speciesList from "@/assets/speciesList.js"
import AnimalCard from "@/components/AnimalCardSimple"
export default {
  props:["animalData"],
  components: {
    AnimalCard
  },
  data() {
    return {
      availableClass: "btn-primary",
      unavailableClass: "btn-secondary",
      onSaleLabel: "",
      breedableLabel: "",
    }
  },
  created(){
    console.log(this.$props.animalData)
  },
  computed: {
    breed() {
      let genus = this.$props.animalData.animal_type
      let breed = ""
      if(genus){
        breed = speciesList[genus].filter( ({id}) => id === this.$props.animalData.animal_breed )
      }
      return breed ? breed[0].name : ""
    },
    sex() {
      let sex = this.$props.animalData.sex
      switch(sex){
        case "male":
          sex = "オス"
          break
        case "female":
          sex = "メス"
          break
      }
      return sex
    },
    isOnSale() {
      return this.$props.animalData ? this.$props.animalData.IsOnSale : ""
    },
    isBreedable() {
      return this.$props.animalData ? this.$props.animalData.IsBreedable : ""
    },
  },
  methods: {
    toggleIsOnSale() {
      this.$store.dispatch("Animal/toggleOnSale")
    },
    toggleIsBreedable() {
      this.$store.dispatch("Animal/toggleIsBreedable")
    },
  },
  watch: {
    isOnSale: function(newVal) {
      console.log(newVal)
      this.onSaleLabel = newVal === '1' ? "○" : "×"
    },
    isBreedable: function(newVal) {
      console.log(newVal)
      this.breedableLabel = newVal === '1' ? "○" : "×"
    },
  }
}
</script>

<style scoped>
  #summary {
    background: url(../../assets/bg_green_grass.jpg) no-repeat 50% 50%;
    background-size: cover;
    display: grid;
    grid-template-columns: repeat( 3, 1fr );
    grid-auto-flow: dense;
    grid-gap: 5px;
  }
  #summary > div {
    height: 320px;
    padding: 20px;
  }
  #summary .primary {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-end: span 2;
  }
  #summary .secondary {
    display: flex;
    align-items: center;
  }
  #summary .secondary ul {
    width: 100%;
  }
  #summary .secondary li {
    margin-bottom: 10px;
  }
  #summary .secondary li:last-child {
    margin-bottom: 0;
  }
  #summary .pic {
    width: 200px;
    flex-basis: 200px;
    height: 200px;
    background: url(../../assets/ico_dog.png) no-repeat 50% 50% #fff;
    background-size: 100px auto;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    border: 5px solid #fff;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.4);
  }
  #summary .pic img {
    width: 100%;
    height: auto;
    transform: scale(1.2);
  }
  #summary .desc {
  	padding: 8px 0;
    font-size: 14px;
    font-weight: bold;
  }
  #summary h3 {
  	margin-bottom: 5px;
  	line-height: 1.3;
    font-size: 1.3rem;
    font-weight: bold;
  }
  #summary h3 i {
  	margin-left: 5px;
    font-size: 0.9rem;
  }
  .parents li {
    position: relative;
  }
  .parents li::before {
    content: "";
    width: 50px;
    height: 5px;
    background-color: #fff;
    position: absolute;
    left: -55px;
  }
  .parents li.mother::before {
    top: 45px;
    transform: rotate(-20deg);
  }
  .parents li.father::before {
    top: 20px;
    transform: rotate(20deg);
  }
  .controls > div {
    display: flex;
    align-items: center;
  }
  .controls button {
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .controls button span {
    font-weight: bold;
    font-size: 1.2rem;
    margin-left: 3px;
  }
</style>
