<template>
  <b-modal
    size="lg"
    title="販売・譲渡記録"
    v-model="active"
    :no-close-on-backdrop=true
    hide-footer
    @hidden="$emit('reset')"
  >

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 180px;">販売／譲渡</span>
        <select
          class="form-select"
          :class="{'is-invalid': !states.genre}"
          v-model="salesData.genre"
        >
          <option>販売</option>
          <option>譲渡</option>
        </select>
        <div class="invalid-feedback">区分を選択してください</div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 180px;">販売・譲渡日</span>
        <input
          type="date"
          class="form-control"
          :class="{'is-invalid': !states.date}"
          v-model="salesData.date"
        >
        <div class="invalid-feedback">販売・譲渡した日付を選択してください</div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 180px;">販売・譲渡先名</span>
        <input
          type="text"
          class="form-control"
          :class="{'is-invalid': !states.buyer}"
          v-model="salesData.buyer"
          placeholder="販売・譲渡先名"
        >
        <div class="invalid-feedback">販売・譲渡先名を入力してください</div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 180px;">販売・譲渡先登録番号</span>
        <input
          type="text"
          class="form-control"
          v-model="salesData.buyerNum"
          placeholder="登録番号"
        >
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 180px;">販売・譲渡先所在地</span>
        <input
          type="text"
          class="form-control"
          :class="{'is-invalid': !states.buyerAddr}"
          v-model="salesData.buyerAddr"
          placeholder="住所または所在地"
        >
        <div class="invalid-feedback">販売・譲渡先所在地を入力してください</div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 180px;">相手方の法令遵守</span>
        <div class="chkbox form-control">
          <input
            type="checkbox"
            id="IsLegalCompliance"
            v-model="salesData.IsLegalCompliance"
          >
          <label for="IsLegalCompliance"><div class="tick"></div></label>
        </div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 180px;">現物確認</span>
        <div class="chkbox form-control">
          <input
            type="checkbox"
            id="IsConfirmInKind"
            v-model="salesData.IsConfirmInKind"
          >
          <label for="IsConfirmInKind"><div class="tick"></div></label>
        </div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 180px;">対面説明</span>
        <div class="chkbox form-control">
          <input
            type="checkbox"
            id="IsConfirmFacetoFace"
            v-model="salesData.IsConfirmFacetoFace"
          >
          <label for="IsConfirmFacetoFace"><div class="tick"></div></label>
        </div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 180px;">顧客確認</span>
        <div class="chkbox form-control">
          <input
            type="checkbox"
            id="IsConfirmCustomer"
            v-model="salesData.IsConfirmCustomer"
          >
          <label for="IsConfirmCustomer"><div class="tick"></div></label>
        </div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 180px;">説明実施者</span>
        <select
          class="form-select"
          :class="{'is-invalid': !states.explanation_imprementer}"
          v-model="salesData.explanation_imprementer"
          ref="tantou"
        >
          <option value="">選択してください</option>
          <option
            v-for="tantou in staffList"
            :key="tantou.id"
          >{{tantou.name}}</option>
        </select>
        <div class="invalid-feedback">説明実施者を選択してください</div>
      </div>
    </fieldset>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
      >キャンセル</button>
      <button
        type="button"
        class="btn btn-primary"
        @click="handleOk"
      >記録する</button>
    </div>

  </b-modal>
</template>

<script>
import axios from "axios"
export default {
  props: ["mode"],
  emits: ["reset"],
  data() {
    return {
      active: false,
      date: "",
      salesData: {
        id: this.$route.params.id,
        genre: "",
        date: "",
        buyer: "",
        buyerNum: "",
        buyerAddr: "",
        IsLegalCompliance: false,
        IsConfirmInKind: false,
        IsConfirmFacetoFace: false,
        IsConfirmCustomer: false,
        explanation_imprementer: "",
      },
      states: {
        genre: true,
        date: true,
        buyer: true,
        buyerNum: true,
        buyerAddr: true,
        IsLegalCompliance: true,
        IsConfirmInKind: true,
        IsConfirmFacetoFace: true,
        IsConfirmCustomer: true,
        explanation_imprementer: true,
      },
    }
  },
  created() {
    this.$store.dispatch("Staff/loadStaffList")
  },
  mounted() {
    this.active = this.$props.mode === "sale" ? true : false
  },
  methods: {
    validate() {
      this.states.genre = this.salesData.genre ? true : false
      this.states.date = this.salesData.date ? true : false
      this.states.buyer = this.salesData.buyer ? true : false
      this.states.buyerAddr = this.salesData.buyerAddr ? true : false
      this.states.explanation_imprementer = this.salesData.explanation_imprementer ? true : false

      let res = true
      let states = {...this.states}
      Object.keys(states).forEach(function(key) {
        res *= states[key]
      })
      return res
    },
    handleOk() {
      // if(!this.validate())
      //   return

      axios
        .post("/wp-json/pasti/v1/log/sales/", this.salesData)
        .then( response => {
          console.log(response)
          this.active = false

          this.retriveLog()
        })
        .catch((error) => {
          console.log(error)
        });
    },
    retriveLog() {
      this.$store.dispatch('AnimalLog/get', {
        id: this.$route.params.id,
      })
    }
  },
  computed: {
    staffList() {
      return this.$store.getters['Staff/staffList']
    },
  }
}
</script>

<style scoped>
.chkbox label {
  position: relative;
  top: 4px;
}
</style>