<template>
  <b-modal
    size="lg"
    title="健康状態記録"
    v-model="active"
    :no-close-on-backdrop=true
    hide-footer
    @hidden="$emit('reset')"
  >

    <fieldset class="row mb-3">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 154px;">記録者</span>
        <select
          class="form-select"
          :class="{'is-invalid': !states.tantou}"
          v-model="comment.tantou"
        >
          <option
            v-for="tantou in staffList"
            :key="tantou.id"
            :value="tantou.name"
          >{{tantou.name}}</option>
        </select>
        <div class="invalid-feedback">記録者を選択してください</div>
      </div>
    </fieldset>

    <fieldset>
      <textarea
        class="form-control"
        v-model="comment.comment"
        :class="{'is-invalid': !states.comment}"
        placeholder="健康状態に変化がある場合はこちらに記入してください"
      ></textarea>
      <div class="invalid-feedback">コメントを入力してください</div>
    </fieldset>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
      >キャンセル</button>
      <button
        type="button"
        class="btn btn-primary"
        @click="handleOk"
      >記録する</button>
    </div>

  </b-modal>
</template>

<script>
import axios from "axios"
export default {
  props: ["mode"],
  emits: ["reset"],
  data() {
    return {
      active: false,
      comment: {
        date: "",
        tantou: "",
        comment: ""
      },
      states: {
        tantou: true,
        comment: true
      }
    }
  },
  created() {
    this.$store.dispatch("Staff/loadStaffList")
  },
  mounted() {
    this.active = this.$props.mode === "healthcheck" ? true : false
  },
  methods: {
    validate() {
      this.states.tantou = this.comment.tantou ? true : false
      this.states.comment = this.comment.comment ? true : false
      let res = true
      let states = {...this.states}
      Object.keys(states).forEach(function(key) {
        res *= states[key]
      })
      return res
    },
    handleOk() {
      if( !this.validate() )
        return

      this.$store.commit('AnimalLog/update', true)
      axios
        .post("/wp-json/pasti/v1/log/healthcheck/", {
          id: this.$route.params.id,
          tantou: this.comment.tantou,
          comment: this.comment.comment,
        })
        .then( response => {
          console.log(response)
          this.$store.commit('AnimalLog/update', false)
          this.active = false
        })
        .catch((error) => {
          console.log(error)
        });
      
    }
  },
  computed: {
    staffList() {
      return this.$store.getters['Staff/staffList']
    },
  },
}
</script>