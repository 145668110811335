<template>
  <header class="p-3 bg-primary text-white">
    <div class="d-flex align-items-center">
      <svg width="40" height="40"><use xlink:href="#kennel"></use></svg>
      <div class="lh-1 mx-2">
        <h1 class="h6 mb-0 text-white lh-1 fw-bold">畜舎</h1>
      </div>
      <div class="control-group">
        <button
          class="btn btn-secondary btn-lg me-2"
          @click='this.$router.back()'
        >一覧に戻る</button>
        <button
          class="btn btn-secondary bg-dark border-dark btn-lg me-2"
          @click.prevent="deleteAnimal"
        >削除</button>
        <button
          class="btn btn-warning btn-lg"
          @click="edit"
        >編集</button>
      </div>
    </div>
  </header>

  <main class="p-3 animaldetail">

    <Basicinfo
      class="mb-3"
      :animalData="animalData"
    />

    <Control
      class="mb-3"
      :animalData="animalData"
      @action="kickAction"
    />

    <AnimalBirth
      v-if="mode==='production'"
      :mode="mode"
      :animalData="animalData"
      @reset="resetAction"
    />

    <AnimalSale
      v-if="mode==='sale'"
      :mode="mode"
      @reset="resetAction"
    />

    <AnimalHealthCheck
      v-if="mode==='healthcheck'"
      :mode="mode"
      @reset="resetAction"
    />

    <DeleteReason
      v-if="mode==='deletereason'"
      :mode="mode"
      @reset="resetAction"
      @reason="kickDeleteAction"
    />

    <AnimalDead
      v-if="mode==='dead'"
      :mode="mode"
      :animalData="animalData"
      @reset="resetAction"
    />

    <LogList />

  </main>
</template>

<script>
  import Basicinfo from '@/components/Animal/Basicinfo'
  import Control from '@/components/Animal/Control'
  import AnimalBirth from '@/components/Modal/AnimalBirth'
  import AnimalSale from '@/components/Modal/AnimalSale'
  import AnimalHealthCheck from '@/components/Modal/AnimalHealthCheck'
  import DeleteReason from '@/components/Modal/AnimalDeleteReason'
  import AnimalDead from '@/components/Modal/AnimalDead'
  import LogList from '@/components/Animal/LogList'

  export default {
    components: {
      Basicinfo,
      Control,
      AnimalBirth,
      AnimalSale,
      AnimalHealthCheck,
      AnimalDead,
      DeleteReason,
      LogList
    },
    data() {
      return {
        mode: "",
        animalData: {}
      }
    },
    created() {
      this.$store.dispatch("Animal/reset")
      this.$store.dispatch("Animal/getInfo", this.$route.params.id)
    },
    mounted() {
      this.$store.watch(
        (state, getters) => getters['Animal/container'],
        (newValue) => {
          // console.log(newValue)
          this.animalData = newValue
        }
      )
    },
    methods: {
      edit() {
        let id = this.$route.params.id
        this.$router.push('/animaledit/' + id)
      },
      deleteAnimal() {
        // if(confirm("削除してよろしいですか？")){
        //   this.$store.dispatch('Animal/delete', this.$route.params.id)
        // }
        //削除事由確認
        this.mode = "deletereason"

        //AnimalSaleModalをkick
        //this.mode = "sale"
      },
      dismissEdit() {
        this.edit = false;
      },
      kickAction(val) {
        this.mode = val
      },
      resetAction() {
        this.mode = false
      },
      kickDeleteAction(action) {
        switch(action){
          case "sold":
            this.mode = "sale"
            break
          case "dead":
            this.mode = "dead"
            break 
        }
      },
    },
    watch: {
      $route(to) {
        this.$store.dispatch("Animal/getInfo", to.params.id)
        this.$store.dispatch('AnimalLog/get', {
          id: to.params.id,
        })
      },
    }
  }
</script>

