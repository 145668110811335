<template>
  <b-modal
    size="lg"
    title="出産記録"
    ref="modal"
    v-model="active"
    :no-close-on-backdrop=true
    hide-footer
    @hidden="$emit('reset')"
  >

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 154px;">担当</span>
        <select
          class="form-select"
          :class="{'is-invalid': !states.tantou}"
          v-model="vaccinData.tantou"
          ref="tantou"
        >
          <option value="">選択してください</option>
          <option
            v-for="tantou in staffList"
            :key="tantou.id"
            :value="tantou.name">{{tantou.name}}</option>
        </select>
        <div class="invalid-feedback">担当者を選択してください</div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 154px;">出産日</span>
        <input
          type="date"
          class="form-control"
          :class="{'is-invalid': !states.birthDate}"
          v-model="vaccinData.birthDate"
          ref="birthDate"
        >
        <div class="invalid-feedback">出産日を入力してください</div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 154px;">交配日</span>
        <input
          type="date"
          class="form-control"
          :class="{'is-invalid': !states.matingDate}"
          v-model="vaccinData.matingDate"
          ref="matingDate"
        >
        <div class="invalid-feedback">交配日を入力してください</div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 154px;">出産方法</span>
        <select
          class="form-select"
          :class="{'is-invalid': !states.method}"
          v-model="vaccinData.method"
          ref="method"
        >
          <option value="">選択してください</option>
          <option>自然分娩</option>
          <option>帝王切開</option>
        </select>
        <div class="invalid-feedback">出産方法を選択してください</div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 154px;">父親</span>
        <select
          class="form-select"
          :class="{'is-invalid': !states.father}"
          v-model="vaccinData.father"
          ref="father"
        >
          <option value="">選択してください</option>
          <option
            v-for="father in maleList"
            :key="father.id"
            :value="father.id">{{father.name}}</option>
        </select>
        <div class="invalid-feedback">父親を選択してください</div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 154px;">出産頭数</span>
        <input
          type="number"
          class="form-control"
          :class="{'is-invalid': !states.numBirth}"
          v-model="vaccinData.numBirth"
          placeholder="出産頭数"
          ref="numBirth"
        >
        <div class="invalid-feedback">出産頭数を入力してください</div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 154px;">健康</span>
        <input
          type="number"
          class="form-control"
          :class="{'is-invalid': !states.numBirthHealthy}"
          v-model="vaccinData.numBirthHealthy"
          placeholder="健康に生まれた子の数"
          ref="numBirthHealthy"
        >
        <div class="invalid-feedback">健康な出産頭数を入力してください</div>
      </div>
    </fieldset>

    <fieldset class="row mb-2">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 154px;">問題あり</span>
        <input
          type="number"
          class="form-control"
          :class="{'is-invalid': !states.numBirthDisease}"
          v-model="vaccinData.numBirthDisease"
          placeholder="健康状態に問題のある子の数"
          ref="numBirthDisease"
        >
        <div class="invalid-feedback">問題がある出産頭数を入力してください</div>
      </div>
    </fieldset>

    <fieldset class="row mb-3">
      <div class="input-group input-group-lg">
        <span class="input-group-text" style="width: 154px;">死産</span>
        <input
          type="number"
          class="form-control"
          :class="{'is-invalid': !states.numBirthDead}"
          v-model="vaccinData.numBirthDead"
          placeholder="死産数"
          ref="numBirthDead"
        >
        <div class="invalid-feedback">死産数を入力してください</div>
      </div>
    </fieldset>

    <fieldset>
      <div>
        <label>備考</label>
        <textarea
          class="form-control"
          v-model="vaccinData.memo"
        ></textarea>
      </div>
    </fieldset>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
      >キャンセル</button>
      <button
        type="button"
        class="btn btn-primary"
        @click="handleOk"
      >記録する</button>
    </div>

  </b-modal>
</template>

<script>
import axios from "axios"
export default {
  props: ["mode", "animalData"],
  emits: ["reset"],
  data() {
    return {
      active: false,
      date: "",
      error: [],
      vaccinData: {
        tantou: "",
        birthDate: "",
        father: "",
        fatherNo: "",
        method: "",
        matingDate: "",
        numBirth: "",
        numBirthHealthy: "",
        numBirthDisease: "",
        numBirthDead: "",
        memo: "",
      },
      states: {
        tantou: true,
        birthDate: true,
        father: true,
        method: true,
        matingDate: true,
        numBirth: true,
        numBirthHealthy: true,
        numBirthDisease: true,
        numBirthDead: true,
        memo: true,
      }
    }
  },
  created() {
    this.$store.dispatch("Staff/loadStaffList")
    this.$store.dispatch("Kennel/loadAnimalList")
  },
  mounted() {
    this.active = this.$props.mode === "production" ? true : false
  },
  methods: {
    validate() {
      this.states.tantou = this.vaccinData.tantou ? true : false
      this.states.birthDate = this.vaccinData.birthDate ? true : false
      this.states.father = this.vaccinData.father ? true : false
      this.states.method = this.vaccinData.method ? true : false
      this.states.matingDate = this.vaccinData.matingDate ? true : false
      this.states.numBirth = this.vaccinData.numBirth ? true : false
      this.states.numBirthHealthy = this.vaccinData.numBirthHealthy ? true : false
      this.states.numBirthDisease = this.vaccinData.numBirthDisease ? true : false
      this.states.numBirthDead = this.vaccinData.numBirthDead ? true : false

      let res = true
      let states = {...this.states}
      Object.keys(states).forEach(function(key) {
        res *= states[key]
      })
      return res
    },
    handleOk() {
      if(!this.validate())
        return

      axios
        .post("/wp-json/pasti/v1/log/birth/", {
          id: this.$route.params.id,
          tantou: this.vaccinData.tantou,
          birthDate: this.vaccinData.birthDate,
          father: this.vaccinData.father,
          fatherNo: this.vaccinData.fatherNo,
          motherNo: this.$props.animalData.microchip_no,
          method: this.vaccinData.method,
          matingDate: this.vaccinData.matingDate,
          numBirth: this.vaccinData.numBirth,
          numBirthHealthy: this.vaccinData.numBirthHealthy,
          numBirthDisease: this.vaccinData.numBirthDisease,
          numBirthDead: this.vaccinData.numBirthDead,
          memo: this.vaccinData.memo,
        })
        .then( response => {
          console.log(response)
          this.active = false
          
          this.retriveLog()
        })
        .catch((error) => {
          console.log(error)
        });
    },
    retriveLog() {
      this.$store.dispatch('AnimalLog/get', {
        id: this.$route.params.id,
      })
    }
  },
  computed: {
    staffList() {
      return this.$store.getters['Staff/staffList']
    },
    maleList() {
      return this.$store.getters["Kennel/maleList"]
    }
  },
  watch: {
    "vaccinData.father": function(newVal) {
      let father = this.maleList.find( animal => animal.id === newVal )
      this.vaccinData.fatherNo = father.number
    }
  }
}
</script>