<template>
  <div class="control">
    <div v-if="animalData.sex==='female'&&animalData.IsBreedable==='1'">
      <button
        @click="$emit('action', 'production')"
      >出産記録</button>
    </div>
    <div v-if="animalData.IsOnSale==='1'">
      <button
        @click="$emit('action', 'sale')"
      >販売・譲渡</button>
    </div>
    <div v-if="animalData.IsDead==='0'">
      <button
        @click="$emit('action', 'healthcheck')"
      >健康状態記録</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["animalData"],
  emits: ["action"],
}
</script>

<style scoped>
  .col button {
    width: 100%;
  }
</style>